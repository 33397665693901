import MultiProducts from "../multiproducts";

import i1 from "./../../../images/products/materialhandling/bucketconveyor/1.jpg";
import i2 from "./../../../images/products/materialhandling/bucketconveyor/2.jpg";
import i3 from "./../../../images/products/materialhandling/bucketconveyor/3.jpg";
import i4 from "./../../../images/products/materialhandling/bucketconveyor/4.jpg";
import i5 from "./../../../images/products/materialhandling/bucketconveyor/5.jpg";
import i6 from "./../../../images/products/materialhandling/bucketconveyor/6.jpg";

const bucketImages = [
    { original: i1, thumbnail: i1 },
    { original: i2, thumbnail: i2 },
    { original: i3, thumbnail: i3 },
    { original: i4, thumbnail: i4 },
    { original: i5, thumbnail: i5 },
    { original: i6, thumbnail: i6 },
];


const data =
{
    id: 'bucket_elevator',
    title: 'Rice Mill Bucket elevator',
    series: 'RCS',
    images: bucketImages,
    video: "",
    description: 'This machine works to move grains from one place to another. This machine is designed in such a way that its current discharges positively and the force acting on it is centrifugal force, spaced buckets dig into the material at input and then throw the material out at discharge.',
    usp: ['Easy Operation', 'Absolute alignment', 'Robust Design', 'High Quality', 'Provide maximum production'],
    specification: [
        ['Product Name', 'Model', 'Capacity Paddy (TPH)', 'Capacity Rice (TPH)'],
        ['Rice Mill Bucket Elevator', 'RCS 150', '10', '15'],
        ['Rice Mill Bucket Elevator', 'RCS 200', '20', '26'],
        ['Rice Mill Bucket Elevator', 'RCS 250', '32', '40'],
        ['Rice Mill Bucket Elevator', 'RCS 300', '40', '50']
    ],
    category: 'Bucket Elevator',
    categoryLink: '/products/bucket_elevator_rcs_300'
}

export default function BucketElevators() {

    return (
        <MultiProducts currentProduct={data}></MultiProducts>
    )
}