import MI0012 from "./../../images/products/multigrader/1.jpg";
import MI0015 from "./../../images/products/multigrader/2.jpg";
import MI0018 from "./../../images/products/multigrader/3.jpg";
import MI0020 from "./../../images/products/multigrader/4.jpg";
import MI0021 from "./../../images/products/multigrader/5.jpg";
import MI0027 from "./../../images/products/multigrader/6.jpg";

let graderImages = [
    { original: MI0012, thumbnail: MI0012 },
    { original: MI0015, thumbnail: MI0015 },
    { original: MI0018, thumbnail: MI0018 },
    { original: MI0020, thumbnail: MI0020 },
    { original: MI0021, thumbnail: MI0021 },
    { original: MI0027, thumbnail: MI0027},
]

const multigrader = [
    {
        id: 'multi-grader-shifter-125',
        title: 'Multi Grader Shifter - 125',
        series: 'Multi Grader Shifter',
        images: graderImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Grader is used to separate rice on the basis of thickness, Multiple sieves are stacked upon each other, with bigger hole size at the top and then whole sizes gets smaller with hierarchy. The grain is fed from the top on to a continuously rotating machine, which in turn separate the broken rice as its diameter from whole rice.',
        usp: ['Easy maintenance and minimum operating cost', 'Inbuilt self-cleaning mechanism to ensure optimum separation', 'Percentage of the broken rice separation is adjustable'],
        specification: [{ 'Product Name': 'Grading' }, { 'Model': 'SHIFTER 125' }, { 'Capacity': '5 TPH' }, { 'Power Consumption (HP) (Recommended)': '3 HP' }, { 'Weight (Kg)': '910' }, { 'Dimensions (L*W*H in mm)': '1505*1280*1620' }],
        technology: ['The final rice is fed into the round perforated or dimpled steel drums, which rotates continuously at the set RPM. The rice kernel of the required length gets stuck into the dimples and taken along to a required height and then dropped into a screw conveyor. The remaining rice of different length is then transferred to other drums for further grading.'],
        category: 'Multi Grader',
        categoryLink: '/multi-grader'
    },
    {
        id: 'multi-grader-shifter-150',
        title: 'Multi Grader Shifter - 150',
        series: 'Multi Grader Shifter',
        images: graderImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Grader is used to separate rice on the basis of thickness, Multiple sieves are stacked upon each other, with bigger hole size at the top and then whole sizes gets smaller with hierarchy. The grain is fed from the top on to a continuously rotating machine, which in turn separate the broken rice as its diameter from whole rice.',
        usp: ['Easy maintenance and minimum operating cost', 'Inbuilt self-cleaning mechanism to ensure optimum separation', 'Percentage of the broken rice separation is adjustable'],
        specification: [{ 'Product Name': 'Grading' }, { 'Model': 'SIFTER 150' }, { 'Capacity': '7 - 8 TPH' }, { 'Power Consumption (HP) (Recommended)': '6/2 HP' }, { 'Weight (Kg)': '1180' }, { 'Dimensions (L*W*H in mm)': '1630*1280*2210' }],
        technology: ['The final rice is fed into the round perforated or dimpled steel drums, which rotates continuously at the set RPM. The rice kernel of the required length gets stuck into the dimples and taken along to a required height and then dropped into a screw conveyor. The remaining rice of different length is then transferred to other drums for further grading.'],
        category: 'Multi Grader',
        categoryLink: '/multi-grader'
    },

]

export default multigrader;