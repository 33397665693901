import beltconveyor from "../../productcategories/materialhandling/beltconveyor";
import MultiProducts from "../multiproducts";

import i1 from "./../../../images/products/materialhandling/beltconveyor/1.jpg";
import i2 from "./../../../images/products/materialhandling/beltconveyor/2.jpg";
import i3 from "./../../../images/products/materialhandling/beltconveyor/3.jpg";
import i4 from "./../../../images/products/materialhandling/beltconveyor/4.jpg";
import i5 from "./../../../images/products/materialhandling/beltconveyor/5.jpg";
import i6 from "./../../../images/products/materialhandling/beltconveyor/6.jpg";

const conveyorimages = [
    { original: i1, thumbnail: i1 },
    { original: i2, thumbnail: i2 },
    { original: i3, thumbnail: i3 },
    { original: i4, thumbnail: i4 },
    { original: i5, thumbnail: i5 },
    { original: i6, thumbnail: i6 }
];


const data =
{
    id: 'Belt_Conveyor',
    title: 'Belt Conveyor',
    series: 'BC',
    images: conveyorimages,
    video: "",
    description: 'A belt conveyor system consists of two or more pulley, with a closed loop of carrying medium- the conveyer belt- that rotates about them, this is used to transport large volumes of resources & agricultural materials, such as grain, seeds, feed etc.',
    usp: ['Durable', 'Expeditious', 'Easy to use', 'Perfectly creates uniform partitions'],
    specification: [
        ['Product Name', 'Model', 'Capacity (TPH)', 'Capacity Rice (TPH)'],
        ['Grain Mover' ,  'BC 450', '15' ,'20' ],
        ['Grain Mover' ,  'BC 600', '24' ,'30' ]
    ],
    category: 'Belt Conveyor'
};

for (let bc of beltconveyor) {
    //  data.specification.push(bc.specification)
}

export default function BeltConveyors() {

    return (
        <MultiProducts currentProduct={data}></MultiProducts>
    )
}