import MultiProducts from "./multiproducts";

import pcs1 from "./../../images/products/precleaners/pcs/1.jpg";
import pcs2 from "./../../images/products/precleaners/pcs/2.jpg";
import pcs3 from "./../../images/products/precleaners/pcs/3.jpg";
import pcs4 from "./../../images/products/precleaners/pcs/4.jpg";
import pcs5 from "./../../images/products/precleaners/pcs/5.jpg";
import pcs6 from "./../../images/products/precleaners/pcs/6.jpg";

const pcsImages = [
    {original: pcs1, thumbnail: pcs1},
    {original: pcs2, thumbnail: pcs2},
    {original: pcs3, thumbnail: pcs3},
    {original: pcs4, thumbnail: pcs4},
    {original: pcs5, thumbnail: pcs5},
    {original: pcs6, thumbnail: pcs6},
]

const data =
{
    id: 'Pre_Cleaner_PCS',
    title: 'PRE CLEANER - PCS',
    series: 'PRE CLEANER - PCS',
    images: pcsImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'It is widely known as the preliminary cleaning, used for cleaning & rinsing the grain. It comes with two congenital sieves alongside an accustomed aspiration to give explicit ramification results. Additionally, it successfully dries & poaches the respective grains.It is equipped with a Rotary drum cleaner and equipped with a Single wooden deck cleaner.(Use HC 70 Picture as its pic is not available.',
    usp: ['Easy-to-use', 'Sustainable machine', 'An expansive screen', 'An all-around cleaning method'],
    specification: [
        ['Product Name', 'Model', 'Capacity Paddy (TPH) ', 'Power Consumption (HP) (Recommended)', 'Weight (Kg)', 'Dimensions (L*W*H in mm)'],
        ['Pre Cleaner', 'PCS 1', '4 - 6', '2+7.5', '1250', '2960*1890*2415'],
        ['Pre Cleaner', 'PCS 2', '8 - 10', '2+7.5', '1400', '2960*1890*2415'],
    ],
    technology: ['however it is equipped with an additional Drum Sieve, which is useful to remove oversized particles. Therefore, in some place it is also used as pre cleaner as well.'],
    category: 'Pre-Cleaner',
    categoryLink: '/pre-cleaner'

}

export default function PreCleanerPcs() {

    return (
        <MultiProducts currentProduct={data}></MultiProducts>
    )
}