import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBm_ehmiKf9AWwnTjh5rLE-LjWBJ6mSi80",
  authDomain: "sona-machinery-website.firebaseapp.com",
  projectId: "sona-machinery-website",
  storageBucket: "sona-machinery-website.appspot.com",
  messagingSenderId: "382280638229",
  appId: "1:382280638229:web:6654e7bb7960e2b067d31c",
  measurementId: "G-8BMY7N9FY8"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
