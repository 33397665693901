import I3513 from "./../../../images/products/materialhandling/screwconveyor/1.jpg";
import I3519 from "./../../../images/products/materialhandling/screwconveyor/2.jpg";
import I3522 from "./../../../images/products/materialhandling/screwconveyor/3.jpg";
import I3530 from "./../../../images/products/materialhandling/screwconveyor/4.jpg";
import I3535 from "./../../../images/products/materialhandling/screwconveyor/5.jpg";
import I3541 from "./../../../images/products/materialhandling/screwconveyor/6.jpg";

let screwImages = [
    { original: I3513, thumbnail: I3513 },
    { original: I3519, thumbnail: I3519 },
    { original: I3522, thumbnail: I3522 },
    { original: I3530, thumbnail: I3530 },
    { original: I3535, thumbnail: I3535 },
    { original: I3541, thumbnail: I3541 },
];

const screwconveyor = [

    {
        id: 'screw-conveyor-sc-200',
        title: 'Screw Conveyor 200',
        series: 'SC',
        images: screwImages,
        video: "",
        description: 'Screw conveyors are used to convey bulk material from almost any position (vertical , horizontal , or at an incline ) and are one of the most reliable methods of conveyance for moving materials.',
        usp: ['Discharge Spouts', 'Supporting Feet & Saddles', 'Durable', 'Quick installation & no regular maintenance'],
        specification: [
            { 'Product Name': 'Grain Feeder' }, { 'Model': 'SC 200' }, { 'Capacity Flour (TPH)': '5' }, { 'Capacity Bran (TPH)': '5' },
        ],
        technology: [],
        category: 'Screw Conveyor',
        categoryLink: '/products/screw-conveyor-sc-300'
    },
    {
        id: 'screw-conveyor-sc-250',
        title: 'Screw Conveyor 250',
        series: 'SC',
        images: screwImages,
        video: "",
        description: 'Screw conveyors are used to convey bulk material from almost any position (vertical , horizontal , or at an incline ) and are one of the most reliable methods of conveyance for moving materials.',
        usp: ['Discharge Spouts', 'Supporting Feet & Saddles', 'Durable', 'Quick installation & no regular maintenance'],
        specification: [
            { 'Product Name': 'Grain Feeder' }, { 'Model': 'SC 250' }, { 'Capacity Flour (TPH)': '10' }, { 'Capacity Bran (TPH)': '10' },
        ],
        technology: [],
        category: 'Screw Conveyor',
        categoryLink: '/products/screw-conveyor-sc-300'
    },
    {
        id: 'screw-conveyor-sc-300',
        title: 'Screw Conveyor 300',
        series: 'SC',
        images: screwImages,
        video: "",
        description: 'Screw conveyors are used to convey bulk material from almost any position (vertical , horizontal , or at an incline ) and are one of the most reliable methods of conveyance for moving materials.',
        usp: ['Discharge Spouts', 'Supporting Feet & Saddles', 'Durable', 'Quick installation & no regular maintenance'],
        specification: [
            { 'Product Name': 'Grain Feeder' }, { 'Model': 'SC 300' }, { 'Capacity Flour (TPH)': '18' }, { 'Capacity Bran (TPH)': '18' },
        ],
        technology: [],
        category: 'Screw Conveyor',
        categoryLink: '/products/screw-conveyor-sc-300'
    },
]

export default screwconveyor;